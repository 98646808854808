import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import BlogRoll from "../components/BlogRoll";

// eslint-disable-next-line
export const IndexPageTemplate = ({
  title,
  subheading,
}) => {
  return (
    <div>
      <section className="section section--gradient has-background-primary">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div>
                <div className="content">
                  <div className="content">
                    <div className="tile">
                      <h1 className="title">{title}</h1>
                    </div>
                    <div className="tile is-7">
                      <h3 className="subtitle has-text-weight-light">{subheading}</h3>
                    </div>
                  </div>
                  <div>
                    <h3 className="has-text-weight-semibold is-size-2 line-after">
                      <span>Projects</span>
                    </h3>
                    {/* <hr align="right" width="80%" className="solid"></hr> */}
                    <br/>
                    <BlogRoll />
                    <br />
                    <br />
                    <div className="has-text-centered is-right">
                      <Link className="btn" to="/projects">
                        More →
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  subheading: PropTypes.string,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        subheading={frontmatter.subheading}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        subheading
      }
    }
  }
`;
